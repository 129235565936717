<template>
  <layout title="Minha Assinatura" :options="[]">
    <v-flex xs12>
      <div style="scale: 0.8" class="expande-horizontal column centraliza">
        <LottieAnimation
          class="pb-0"
          ref="anim"
          :loop="true"
          :animationData="require('@/apps/shared/assets/silver_plan.json')"
        />
        <h1 class="fonte mb-3">Plano válido até {{ dateExpire }}!</h1>
        <div style="width: 400px;" class=" mb-6 expande-horizontal centraliza">
          <v-progress-linear
            :color="$theme.secondary"
            class="mt-3"
            height="30"
            style="border-radius: 10px;"
            :value="100"
            striped
          >
            <template v-slot:default="{ value }">
              <strong> Restam {{ daysLeft }} dias </strong>
            </template>
          </v-progress-linear>
        </div>

        <v-btn
          @click="$router.push('/checkout')"
          :color="$theme.secondary"
          class="fonte text-capitalize"
          dark
          large
        >
          Assinar um plano
        </v-btn>
      </div>
    </v-flex>
  </layout>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  components: {
    LottieAnimation
  },
  data() {
    return {
      moment: moment,
      stage: 1,
      card: {
        number: "",
        verification_value: "",
        expiration: "",
        full_name: ""
      }
    };
  },
  computed: {
    ...mapGetters(["getLoggedUser"]),
    daysLeft() {
      const expires =
        this.getLoggedUser.tenant[0].active_plan.expires_at || new Date();
      const days = moment(expires).diff(moment(), "days");
      return days;
    },
    dateExpire() {
      const expires =
        this.getLoggedUser.tenant[0].active_plan.expires_at || new Date();
      return moment(expires).format("DD/MM/YYYY [às] HH:mm");
    }
  },
  methods: {
    createPaymentToken() {
      window.Iugu.createPaymentToken(this.card, res => {
        if (res.errors) {
          console.log("erro ao criar cartão");
        } else {
          console.log("token criado", res.id);
        }
      });
    }
  },
  created() {
    window.Iugu.setAccountID(process.env.VUE_APP_ID_ACCOUNT_IUGU);
    window.Iugu.setTestMode(true);
    window.Iugu.setup();
  }
};
</script>
